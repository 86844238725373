"use client";

import React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import { cn } from "../utils";

interface CheckboxProps {
  children?: React.ReactNode;
  name?: string;
  onClick?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
}

/**
 * @deprecated  Use `Checkbox` from `@fscrypto/ui` instead.
 */
const CheckboxLegacy = ({ children, name, onClick, checked, disabled }: CheckboxProps) => {
  return (
    <label htmlFor={name} className="flex items-center gap-x-2">
      <input
        type="checkbox"
        name={name}
        onChange={(e) => onClick?.(e.currentTarget.checked)}
        checked={checked}
        disabled={disabled}
        className="hover:disabled:border-gray-20 disabled:bg-gray-10 border-gray-20 h-4 w-4 rounded-md checked:bg-blue-50 hover:border-blue-50"
      />
      <span>{children}</span>
    </label>
  );
};

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "border-primary ring-offset-background focus-visible:ring-ring data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground peer h-4 w-4 shrink-0 rounded-sm border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")}>
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { CheckboxLegacy, Checkbox };
